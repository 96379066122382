import React from "react";
import "./index.css";
export default function WaveLoading() {
  return (
    <div className="grid place-items-center h-screen">
      <div className="waveform">
        <div className="waveform__bar"></div>
        <div className="waveform__bar"></div>
        <div className="waveform__bar"></div>
        <div className="waveform__bar"></div>
      </div>
    </div>
  );
}
