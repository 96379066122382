import { EncryptStorage } from "encrypt-storage";
import { format } from "date-fns";
import { id, enUS } from "date-fns/locale";

const numberWithCommas = (number) => {
  return number?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

const regexThousandSeparator = (number) => {
  if (number?.split(".")?.length > 2) {
    number = number?.replace(/\.+$/, "");
  }
  var regex = /^[0-9]*\.?[0-9]*$/;
  let val = number.match(regex);
  return val || null;
};

const globalStorage = new EncryptStorage("global_params", {
  storageType: "localStorage",
  encAlgorithm: "Rabbit",
});

const fetchEncryptId = (id) => {
  try {
    const params = id?.toString() || "";
    const code = JSON.parse(atob(params));
    return code;
  } catch (e) {
    return false;
  }
};

const convertDate = ({ date, displayFormat = "yyyy-MM-dd", language = false }) => {
  let defDate = date ? new Date(date) : new Date();
  let formatLanguage = language ? id : enUS;
  return format(defDate, displayFormat, { locale: formatLanguage });
};

const convertToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const API = import.meta.env.VITE_URL_API;

const findNode = (param, currentNode) => {
  for (const node of currentNode) {
    if (node.action === param) return node;
    if (node.children) {
      const child = findNode(param, node.children);
      if (child) return child;
    }
  }
};

const fileSize = (size) => {
  if (size === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export {
  numberWithCommas,
  fetchEncryptId,
  convertDate,
  globalStorage,
  API,
  regexThousandSeparator,
  convertToBase64,
  findNode,
  fileSize,
};
